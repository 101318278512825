import * as React from "react";
import Contact from "../modals/Contact";
import Profile from "../modals/Profile";
import Email from "../modals/Email";
import Referral from "../modals/Referral";
import Book from "../modals/Book";
import Activity from "../modals/Activity";
import Progress from "./Progress";
import Company from "../modals/Company";
import promptr from '../util/api';
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import LoadingOverlay from 'react-loading-overlay';
import PaginatedUsers from "./PaginatedUser";
import User from "../modals/User";
import Login from "../modals/Login";
import {withSnackbar} from 'notistack';
import Sheet from 'react-modal-sheet';
import CompanyProfile from "./CompanyProfile";
import CompanyUpdate from "./CompanyUpdate";
import AddContact from "../modals/AddContact";
import TrialAlert from "./TrialAlert";
import PaginatedActivity from "./PaginatedActivity";
import UserActivity from "./UserActivity";
import ContactProfileCard from "./ContactProfileCard";


/* global require */

const initialState = {
    full_name: '',
    first_name: '',
    last_name: '',
    email_address: '',
    linkedIn_profile_url: null,
    contact: {},
    is_my_contact: false,
    mobile: '',
    engagement_score: null,
    engagement_score_color: null,
    engagement_score_percentage: 0,
    contact_email_address: null,
    company_name: '',
    title: '',
    contact_class: '',
    current_date: null,
    user_display_name: '',
    user_full_name: '',
    user_first_name: '',
    user_last_name: '',
    user_email: null,
    user_job_title: null,
    user_mobile: null,
    user_department: null,
    user_topics: null,
    user_team: null,
    user: {},
    known_by: null,
    activities: null,
    access_token: localStorage.getItem('access_token'),
    profile: localStorage.getItem('user'),
    options: null,
    address: '',
    search_is_loading: false,
    page_is_loading: false,
    search_results: [],
    search_type: 'contact',
    search_placeholder: 'Search for a contact',
    companies: null,
    tags: null,
    pageCount: 0,
    itemsOffset: 0,
    isLoggedIn: false,
    sign_in_code_sent: false,
    sign_in_code_error: '',
    contact_exists: false,
    company: null,
    show_company: false,
    settings: null,
    report_url: null,
    can_auto_login: false,
}

class App extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.loginModal = React.createRef();
        this.state = initialState;
    }


    componentDidMount() {

        let {isOfficeInitialized} = this.props;
        Office.onReady(() => {
            console.log('Initialising Office');
        }).then(r => {
            console.log("Initialized");
            this.setState({isLoggedIn: this.state.access_token != null});
            isOfficeInitialized = true;
            this.initialize().then();
            Office?.context?.mailbox?.addHandlerAsync(Office.EventType.ItemChanged, this.updateAddIn);
            this.UpdateTaskPaneUI(Office.context.mailbox.item).then(r => null);
            const contextInfo = Office.context.platform;
            console.log('Checking Platform');
            console.log(contextInfo);
        });
    }

    refreshToken = async (tk = null) => {
        const token = tk ?? localStorage.getItem('access_token');
        if (token != null) {
            try {
                const response = await promptr.post("auth/refresh?access_token=" + token, {}, {
                    headers: {'Authorization': 'Bearer ' + token},
                });
                let responseBody = response.data;
                switch (response.status) {
                    case 200:
                        this.loginUser(responseBody);
                        break;
                    case 422:
                        await this.logout();
                        break;
                }

                console.log('Refresh token response');
                console.log(responseBody);

            } catch (error) {
                console.log('Refresh token error');
                console.log(error);
            }

        }
    }


    initializeBrowserSession = async () => {
        const tempoToken = sessionStorage.getItem('tempo_token');
        if (tempoToken != null) {
            console.log('Tempo token is ' + tempoToken);
            await this.refreshToken(tempoToken);
        }
    }

    initialize = async () => {

        await this.configureAddIn();
        await this.getOptions();

        if (!this.state.isLoggedIn) {

            if (sessionStorage.getItem('tempo_token')) {
                await this.initializeBrowserSession();
            } else {
                if (this.state.can_auto_login) {
                    await this.autoLogin();
                } else {
                    this.loginModal.current?.click();
                }
            }
        } else {
            let data = this.state.profile;
            this.setState({
                user_first_name: data?.first_name,
                user_last_name: data?.last_name,
                user_email: data?.email,
                user_mobile: data?.mobile,
                user_department: data?.department,
                user_job_title: data?.user_job_title,
                user_team: data?.team_id,
            });
        }
    }

    autoLogin = async () => {
        try {

            this.setState({
                page_is_loading: true
            });

            const response = await promptr.post("auth/login", {
                email: this.state.user_email,
                auto_login: this.state.can_auto_login
            });

            let responseBody = response.data;
            switch (response.status) {
                case 200:
                    this.loginUser(responseBody);
                    this.setState({
                        page_is_loading: false
                    });
                    break;
                case 422:
                    this.props.enqueueSnackbar(responseBody.message, {
                        variant: 'error',
                    });
                    break;
            }
            console.log('Email response');
            console.log(responseBody);

        } catch (error) {
            this.props.enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
                variant: 'error',
            });
            console.log('Email adding error');
            console.log(error);
        }
    };

    updateAddIn = async () => {
        // Update UI based on the new current item
        console.log('Item changed, updating UI');
        await this.UpdateTaskPaneUI(Office.context.mailbox.item);
    }


    configureAddIn = async () => {

        try {
            const response = await promptr.get();
            if (response.status === 200) {
                let data = response.data;
                this.setState({
                    settings: data,
                    report_url: data?.report_url,
                    can_auto_login: data?.can_auto_login
                });
            }
        } catch (error) {
            console.log('Options details error');
            console.log(error);
        }
    }

    initiateSignIn = async () => {
        console.log('Sending in sign in code');
        let userDetails = Office.context.mailbox?.userProfile;
        try {
            const response = await promptr.post("auth/send-code", {
                email: userDetails.emailAddress,
            });
            let responseBody = response.data;
            switch (response.status) {
                case 200:
                    this.setState({sign_in_code_sent: true});
                    this.props.enqueueSnackbar(responseBody.message, {
                        variant: 'success',
                    });
                    break;
                case 422:
                    this.setState({sign_in_code_sent: false, sign_in_code_error: responseBody.message});
                    this.props.enqueueSnackbar(responseBody.message, {
                        variant: 'warning',
                    });
                    break;
            }

            console.log('Sign in  response');
            console.log(responseBody);

        } catch (error) {
            console.log('Email adding error');
            console.log(error);
            this.setState({sign_in_code_error: error?.response?.data?.message});
            this.props.enqueueSnackbar(error?.response?.data?.message, {
                variant: 'error',
            });
        }
    }

    /**
     *
     * @param item
     * @constructor
     */
    UpdateTaskPaneUI = async (item) => {
        let senderDetails = item?.from;
        let userDetails = Office.context.mailbox?.userProfile;
        console.log(senderDetails);
        if (senderDetails != null) {
            let fullNameSplit = senderDetails.displayName.split(' ');
            this.setState({
                full_name: senderDetails.displayName,
                first_name: fullNameSplit[0] ?? '',
                last_name: fullNameSplit[1] ?? '',
                email_address: senderDetails.emailAddress,
                contact_email_address: senderDetails.emailAddress,
                contact: senderDetails
            });
        }
        if (userDetails != null) {
            let nameSplit = userDetails.displayName.split(' ');

            this.setState({
                user_full_name: userDetails.displayName,
                user_first_name: nameSplit[0] ?? '',
                user_last_name: nameSplit[1] ?? '',
                user_email: userDetails.emailAddress,
                user: userDetails
            });
            await this.getContactDetails();
        }
    };

    /**
     *
     * @returns {Promise<void>}
     */
    getOptions = async () => {

        try {

            const response = await promptr.get("contacts/options");
            if (response.status === 200) {
                let data = response.data;
                this.setState({
                    options: data,
                });
                console.log('Options details loaded from MAIN');
                console.log(data);
            }
        } catch (error) {
            console.log('Options details error');
            console.log(error);
        }
    }


    resetContactDetails = () => {
        this.setState({
            address: '',
            companies: null,
            tags: null,
            first_name: '',
            last_name: '',
            linkedIn_profile_url: null,
            contact: {},
            mobile: '',
            engagement_score: null,
            engagement_score_color: null,
            engagement_score_percentage: 0,
            contact_email_address: null,
            company_name: '',
            title: '',
            contact_class: '',
            known_by: null,
            activities: null,
        });
    }

    getContactDetails = async (email = null, reload = true) => {

        let emailAddress = email ?? this.state.email_address;
        this.setState({page_is_loading: reload});

        try {

            this.resetContactDetails();

            const response = await promptr.get("contacts/details?contact=" + emailAddress, {
                headers: {'Authorization': 'Bearer ' + this.state.access_token},
            });
            console.log(response);
            let fullName = this.state.full_name;
            let firstName = '';
            let lastName = '';
            if (fullName.length > 0) {
                let fullNameSplit = fullName.split(' ');
                firstName = fullNameSplit[0] ?? firstName;
                lastName = fullNameSplit[1] ?? lastName;
            }

            if (response.status === 200) {
                let data = response.data;
                this.setState({
                    known_by: data.known_by,
                    company_name: data.company_name,
                    email_address: data.email,
                    first_name: data.first_name ? data.first_name : firstName,
                    full_name: data.first_name ? data.first_name + ' ' + data.last_name : fullName,
                    last_name: data.last_name ? data.last_name : lastName,
                    mobile: data.mobile,
                    linkedIn_profile_url: data?.linkedIn_profile_url,
                    title: data.job_title,
                    address: data.address,
                    contact: data?.contact,
                    is_my_contact: data.is_my_contact,
                    current_date: data?.current_date,
                    contact_class: data.contact_class,
                    engagement_score_color: data.engagement_score_color,
                    engagement_score: data.engagement_score,
                    engagement_score_percentage: data.engagement_score_percentage,
                    page_is_loading: false,
                    companies: data.companies,
                    contact_email_address: emailAddress,
                    tags: data.tags,
                    contact_exists: data.contact != null,
                });
                console.log('Contact details loaded');
                console.log(data);

                return data;
            }
        } catch (error) {
            console.log('Get contact details error');
            console.log(error);
            this.setState({page_is_loading: false});
        }

        return null;
    }

    getContact = async (email = null) => {
        return await this.getContactDetails(email);
    }

    /**
     *
     * @param email
     * @returns {Promise<void>}
     */
    getCompanyDetails = async (email = null) => {

        let companyID = email;
        this.setState({page_is_loading: true});
        try {
            const response = await promptr.get("companies/" + companyID, {
                headers: {'Authorization': 'Bearer ' + this.state.access_token},
            });
            console.log(response);

            if (response.status === 200) {
                let data = response.data;
                this.setState({
                    page_is_loading: false,
                    company: data,
                    show_company: true,
                });
                console.log('Company details loaded');
                console.log(data);
            }
        } catch (error) {
            console.log('Get Company details error');
            console.log(error);
            this.props.enqueueSnackbar('Company profile could not be loaded, please try again or contact your administrator', {
                variant: 'error',
            });
            this.setState({page_is_loading: false});
        }
    }

    updateProfile = (data) => {
        localStorage.setItem('profile', JSON.stringify(data));
        this.setState({
            user_first_name: data?.first_name,
            user_last_name: data?.last_name,
            user_email: data?.email,
            user_mobile: data?.mobile,
            user_department: data?.department,
            user_team: data?.team_id,
            user: data,
        });
    }

    loginUser = (data) => {
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('profile', JSON.stringify(data?.user));
        localStorage.setItem('user_topics', data?.user?.topics);
        this.setState({
            access_token: data.access_token,
            user_first_name: data?.user?.first_name,
            user_last_name: data?.user?.last_name,
            user_email: data?.user?.email,
            user_mobile: data?.user?.mobile,
            user_department: data?.user?.department,
            user_team: data?.user?.team_id,
            user: data?.user,
            sign_in_code_sent: true,
            isLoggedIn: true,
        });

        this.props.enqueueSnackbar(data?.message || 'You are now logged in', {
            variant: 'success',
        });
    }

    logout = async () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('profile');
        localStorage.removeItem('user_topics');
        this.setState({
            isLoggedIn: false
        });
        this.props.enqueueSnackbar('You have been successfully logged out');
        await this.updateAddIn();
        if(!this.state.can_auto_login) {
            this.loginModal.current?.click();
        }
        // Log the user out of the server
    }


    click = async () => {
        /**
         * Insert your Outlook code here
         */
    };

    handleSearch = async (query) => {
        this.setState({
            search_is_loading: true,
            query: query
        })

        let search_type = this.state.search_type ?? 'contact';

        try {
            const response = await promptr.get(`contacts?q=${query}&type=${search_type}&page=1&per_page=50`);
            if (response.status === 200) {
                console.log('contact results loaded');
                let data = response.data;
                console.log(data);

                this.setState({
                    search_results: data,
                    search_is_loading: false,
                })
            }
        } catch (error) {
            console.log('contact results error');
            console.log(error);
        }
    };


    handleSearchItemSelected = async (selectedOption) => {
        if (selectedOption.length > 0) {
            this.state.search_type === 'company' ? await this.getCompanyDetails(selectedOption[0]?.id) : await this.getContactDetails(selectedOption[0]?.id);
        }
    }

    filterBy = () => true;

    renderLogout = () => {
        return <a href="javascript:void(0)" onClick={() => this.logout()} className="mr-3">Log Out</a>
    }

    renderCompanies = () => {

        return this.state.companies != null ? this.state.companies.map((company) => {
            return (
                <a className='text-blue-500 mr-2 text-decoration-underline' href="javascript:void(0)"
                   onClick={() => this.getCompanyDetails(company?.id)}>{company?.name},</a>
            )
        }) : <span className="text-black-300">{this.state.company_name}</span>;
    }

    onCompanyModalClose = () => {
        this.setState({
            company: null,
            show_company: false,
        })
    }

    openBrowserLink = async (link) => {
        try {
            Office?.context?.ui?.displayDialogAsync(link);
        } catch (e) {
            window.open(link, '_newtab');
        }

    }

    newEmailDialog = async (email) => {
        const object = this;
        Office.context.mailbox.displayNewMessageFormAsync({
            toRecipients: [email],
            ccRecipients: [],
            subject: "",
            htmlBody: '',
        }, async function (asyncResult) {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                try {
                    const response = await promptr.post("activities/new", {
                        contact: email,
                        type: 0
                    }, {
                        headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
                    });
                    let responseBody = response.data;
                    switch (response.status) {
                        case 200:
                            console.log('Successful');
                            await object.getContactDetails(email, false);
                            break;
                        case 422:
                            console.log('Failed');
                            break;
                    }
                    console.log(responseBody);

                } catch (error) {
                    console.log('Email adding error');
                    console.log(error);
                }
            }
            //  console.log(JSON.stringify(asyncResult.status));
        });

    }

    renderFreeTrial = () => {
        return this.state.settings?.show_free_trial ?
            <TrialAlert message={this.state.settings?.free_trial_message}/> : '';
    }

    render() {
        const {title, isOfficeInitialized} = this.props;
        if (!isOfficeInitialized) {
            return (
                <Progress
                    title={title}
                    logo={require("./../../../assets/promptr.png")}
                    message="Fully Managed CRM Service to help law firms impress clients and do BD more easily"
                />
            );
        }

        const asyncTypeHeadRef = React.createRef();
        const onSearchTypeChange = (type) => {
            this.setState({
                search_type: type,
                search_placeholder: 'Search for a ' + type,
                search_results: [],
            });
            asyncTypeHeadRef.current.clear();
        };

        const openNewEmailDialog = async (email) => {
            await this.newEmailDialog(email);
        }

        return (
            <LoadingOverlay
                active={this.state.page_is_loading}
                spinner
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'transparent',
                    }),
                    content: (base) => ({
                        ...base,
                        color: '#333',
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: 'rgb(103,132,86)'
                        }
                    })
                }}
                text=''
            >
                <div>
                    <a href="#login" ref={this.loginModal} style={{visibility: 'hidden', display: 'none'}}></a>
                    <div className="row">
                        {this.renderFreeTrial()}
                        {!this.state.contact_exists ?
                            <div className="alert mb-0 shadow-md card alert-warning" style={{borderRadius: 0}}>
                                <div>
                                    <div style={{display: 'table'}}>
                                        <h1 className="font-bold mb-1 text-xl text-uppercase">Person Not found</h1>
                                        <div className="text-">To add them,
                                            click [add contact] button
                                        </div>
                                    </div>
                                </div>

                            </div> :
                            <></>
                        }
                        <div className="col-12 text-sm text-blue-500 text-center mt-4 mb-1">
                            <div className="col-12">
                                {this.state.isLoggedIn ?
                                    <a href="#user" className="mr-3 text-decoration-underline">My Profile</a> :
                                    <a href="javascript:void(0)" onClick={() => this.initialize()}
                                       className="mr-3 text-decoration-underline">Login</a>}

                                <a href="https://promptr.info/bd-tips/" target="_blank"
                                   className="mr-3 text-decoration-underline">Tips</a>
                                <a href="https://promptr.info/help/" target="_blank"
                                   className="mr-3 text-decoration-underline"> Help</a>
                                <a href="#" onClick={() => openNewEmailDialog('support@promptr.co.uk')}
                                   className="mr-3 text-decoration-underline">
                                    Support</a>
                            </div>
                        </div>
                        <div className="col-12 mb-2 pb-10 mt-2">
                            <ContactProfileCard
                                contact={this.state.contact}
                                contact_exists={this.state.contact_exists}
                                is_loading={this.state.page_is_loading}
                                is_logged_in={this.state.isLoggedIn}
                                known_by={this.state.known_by}
                                engagement_score={this.state.engagement_score}
                                engagement_score_color={this.state.engagement_score_color}
                                engagement_score_percentage={this.state.engagement_score_percentage}
                                openNewEmailDialog={this.newEmailDialog}
                                companies={this.state.companies}
                                company_name={this.state.company_name}
                                openBrowserLink={this.openBrowserLink}
                                getCompanyDetails={this.getCompanyDetails}
                            />
                            {this.state.isLoggedIn ?
                                <div className="col-12">
                                    <div className="pb-3 pt-3">
                                        <div className="row">
                                            <a className="col-12 mt-3 text-center"
                                               href={this.state.report_url + "?api_token=" + this.state.access_token}
                                               target="_blank">
                                                <div className="display-block btn-dark-gray p-2 rounded-xl text-white">
                                                    View all our BD and dashboards
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="col-12">
                                    <div className="pb-3 pt-3">
                                        <div className="row">
                                            <a className="col-12 mt-3 text-center"
                                               href="#login">
                                                <div className="display-block btn-dark-gray p-2 rounded-xl text-white">
                                                    View all our BD and dashboards
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            }
                            <div className="col-12 mt-2 p-3 pt-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group ">
                                            <AsyncTypeahead
                                                className="form-control bg-green-100 border-green-400 h-12 rounded-2xl text-sm border-1 search-box"
                                                filterBy={this.filterBy}
                                                id="async-contacts"
                                                isLoading={this.state.search_is_loading}
                                                labelKey={option => this.state.search_type === 'company' ? `${option?.name}` : `${option?.first_name} ${option?.last_name}`}
                                                minLength={2}
                                                onSearch={this.handleSearch}
                                                onChange={this.handleSearchItemSelected}
                                                ref={asyncTypeHeadRef}
                                                options={this.state.search_results}
                                                placeholder={this.state.search_placeholder}
                                                renderMenuItemChildren={(option, props) => (
                                                    <div>
                                                        <div className='display-block text-lg'>
                                                            {this.state.search_type === 'company' ? option?.name : option?.full_name}
                                                        </div>
                                                        <div>
                                                            <small
                                                                className='text-gray-600 text-sm font-semibold'>
                                                                {this.state.search_type === 'company' ? option?.total_contacts + ' contacts' : option?.company_names}
                                                            </small>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <input type="radio" name="search"
                                               onChange={() => onSearchTypeChange('contact')} value='contact'
                                               className="radio"
                                               checked={this.state.search_type === 'contact'}/> Contacts &nbsp; &nbsp;
                                        <input type="radio" name="search"
                                               onClick={() => onSearchTypeChange('company')} value='company'
                                               className="radio"
                                               checked={this.state.search_type === 'company'}/> Company
                                    </div>
                                </div>


                               <UserActivity contact={this.state.contact} options={this.state.options}/>

                                <div className="row text-blue-500 mt-5 text-center mt-4">
                                    <div className="col-12">
                                        <img width="150" src="/assets/promptr.png" className="logo-center"/>
                                    </div>
                                    {this.state.isLoggedIn && (
                                        <div className="col-12 mt-2">
                                            {this.renderLogout()}
                                        </div>)}

                                </div>
                            </div>
                        </div>
                        <CompanyProfile isOpen={this.state.show_company} options={this.state.options}
                                        onUpdate={this.getCompanyDetails}
                                        closeCompanyModal={this.onCompanyModalClose} company={this.state.company}
                                        onContactSelected={this.getContactDetails}/>
                    </div>

                    <AddContact contact={this.state.contact} onUpdate={this.getContactDetails}/>
                    <Profile contactData={this.state.contact}
                             companiesData={this.state.companies}
                             tagsData={this.state.tags}
                             options={this.state.options}
                             onUpdate={this.getContact}
                        //  setContact={this.setContactDetails}
                             isLoggedIn={this.state.isLoggedIn}
                    />

                    <Contact onSuccess={this.getContactDetails}
                             contact_exists={this.state.contact_exists}
                             company_name={this.state?.company?.name}
                             first_name={this.state.first_name}
                             last_name={this.state.last_name}
                             full_name={this.state.full_name}
                             job_title={this.state.title}
                             email={this.state.contact_email_address}
                             isLoggedIn={this.state.isLoggedIn}
                    />
                    <Referral/>
                    <Activity contact_exists={this.state.contact_exists}
                              onUpdate={this.getContactDetails}
                              contact_name={this.state.full_name}
                              company={this.state.company}
                              companies={this.state.companies}
                              contact_email={this.state.email_address}
                              user_email={this.state.user_email}
                              user_name={this.state.user_full_name}
                              is_company={this.state.show_company}
                              contact={this.state.contact}
                              options={this.state.options}
                              isLoggedIn={this.state.isLoggedIn}
                    />

                    <User
                        onProfileUpdate={this.updateProfile}
                        options={this.state.options}
                        first_name={this.state.user_first_name}
                        full_name={this.state.user_full_name}
                        last_name={this.state.user_last_name}
                        mobile={this.state.user_mobile}
                        email={this.state.user_email}
                        job_title={this.state.user_job_title}
                        department={this.state.user_department}
                        team={this.state.user_team}
                        topics={this?.state?.user?.interest_topics}
                        isLoggedIn={this.state.isLoggedIn}
                    />

                    <Email/>
                    <Company/>
                    <Login sign_in_code_error={this.state.sign_in_code_error} login={this.loginUser}
                           resendCode={this.initiateSignIn}
                           email={this.state.user_email} user_name={this.state.user_full_name}/>
                </div>
            </LoadingOverlay>
        );
    }
}

export default withSnackbar(App);

