import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Sheet from "react-modal-sheet";
import PaginatedUsers from "./PaginatedUser";
import PaginatedContact from "./PaginatedContact";
import CompanyUpdate from "./CompanyUpdate";

function CompanyProfile(props) {
    const [company, setCompany] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const closeModalRef = useRef(null);

    React.useEffect(() => {
        setCompany(props?.company);
        setIsOpen(props?.isOpen);
    }, [props?.company, props?.isOpen]);


    const onContactSelected = (contact) => {
        props?.onContactSelected(contact);
        setIsOpen(false);
    }

    const closeModal = () => {
        props?.closeCompanyModal();
        setIsOpen(false);
    }

    return (
        <div className="col-12">
            <Sheet isOpen={isOpen} onClose={closeModal}>
                <Sheet.Container>
                    <Sheet.Header>
                        <a href="#" ref={closeModalRef} onClick={closeModal}
                           className="btn btn-sm close-btn btn-circle absolute right-2 top-2 btn-close-company">✕</a>
                        <div className="row mt-3 pt-2">
                            <div className="display-block text-center mb-2">
                <span className="text-gray-600 text font-semibold">
                  COMPANY PROFILE </span>
                            </div>
                        </div>
                    </Sheet.Header>
                    <Sheet.Content>
                        <div className="col-12 mt-2 mb-3">
                            <div className="card bg-gradient shadow-md">
                                <div className="card-body p-4 pt-3 pb-0">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="display-block">
                                                <a href="#companyEdit"
                                                   className="text-blue-800 text-decoration-underline  text-md font-semibold">
                                                    <span>{company?.name}</span> <span
                                                    className="material-icons ml-2 text-black md-18">edit</span>
                                                </a>
                                            </div>
                                            <div className="display-block text-sm">
                                                <b className='break'>
                                                    {company?.holding_organisation_name}
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 text-center p-1">
                                            <a href="#contact" className="display-block text-sm">
                                                <div className="display-block bg-green-500 p-2 rounded-xl">
                                                    <span className="text-white  ">
                                                        Add Contact </span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-6 text-center p-1">
                                            <a href="#activity" className="display-block text-sm">
                                                <div className="display-block bg-green-500 p-2 rounded-xl">
                                                    <span className="text-white ">Add Activity </span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider mb-0 pb-0"/>
                                <div className="card-body p-4 pt-0 pb-0 mb-0 flex flex-row w-full ">
                                    <div className="row">
                                        <div className="display-block mb-2">
                <span className="text-gray-600 text-sm text-center font-semibold">
                  PROFILE SUMMARY </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={'card-body p-0  mb-0'}>
                                    <div className="text-sm border-gray-200 table-responsive">
                                        <div className="text-sm border-gray-200 ">
                                            <table className='col-12'>
                                                <tbody>
                                                <tr style={{borderBottom: '1px solid #3333331f'}}>
                                                    <th width={'30%'} className={'px-4 py-1 text-sm'}>
                                                        Mobile
                                                    </th>
                                                    <td  className={'px-3 py-1 text-sm'}>
                                                        {company?.mobile}
                                                    </td>
                                                </tr>
                                                <tr style={{borderBottom: '1px solid #3333331f'}}>
                                                    <th width={'50%'} className={'px-4 py-1 text-sm'}>
                                                        Website
                                                    </th>
                                                    <td  className={'px-4 py-1 text-sm'}>
                                                        {company?.website}
                                                    </td>
                                                </tr>
                                                <tr style={{borderBottom: '1px solid #3333331f'}}>
                                                    <th nowrap width={'50%'} className={'px-4 py-1 text-sm'}>
                                                        Company Types
                                                    </th>
                                                    <td  className={'px-3 py-1 text-sm'}>
                                                        {company?.company_types?.map((i) => i.name)?.join(', ')}
                                                    </td>
                                                </tr>
                                                <tr style={{borderBottom: '1px solid #3333331f'}}>
                                                    <th nowrap width={'50%'} className={'px-4 py-1 text-sm'}>
                                                        Company Statuses
                                                    </th>
                                                    <td  className={'px-3 py-1 text-sm'}>
                                                        {company?.company_statuses?.map((i) => i.name)?.join(', ')}
                                                    </td>
                                                </tr>
                                                <tr style={{borderBottom: '1px solid #3333331f'}}>
                                                    <th nowrap width={'50%'} className={'px-4 py-1 text-sm'}>
                                                        Industry Sector
                                                    </th>
                                                    <td  className={'px-3 py-1 text-sm'}>
                                                        {company?.industry_sector?.name}
                                                    </td>
                                                </tr>
                                                <tr style={{borderBottom: '1px solid #3333331f'}}>
                                                    <th width={'50%'} className={'px-4 py-1 text-sm'}>
                                                        Client Fee
                                                    </th>
                                                    <td  className={'px-3 py-1 text-sm'}>
                                                        {company?.client_fee?.name}
                                                    </td>
                                                </tr>
                                                <tr style={{borderBottom: '1px solid #3333331f'}}>
                                                    <th width={'50%'} className={'px-4 py-1 text-sm'}>
                                                        Tags
                                                    </th>
                                                    <td  className={'px-3 py-1 text-sm'}>
                                                        {company?.tags?.map((i) => i.name)?.join(', ')}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body p-4 pt-0 pb-0 mb-0 mt-2 flex flex-row w-full ">
                                    <div className="h-38 col">
                                        <div className="display-block mb-2">
                <span className="text-black-400 text-xs font-semibold">
                 KNOWN BY </span>
                                        </div>
                                        <div className="display-block mb-2">
                   <span className="mr-2 text-xs   text-black-300 ">
                       {company?.known_by != null ? company?.known_by?.join(', ') : 'No known users'}
              </span>
                                        </div>
                                    </div>
                                    <div className="divider divider-vertical"></div>
                                    <div className="col h-32">
                                        <div className="display-block mb-2">
                <span className="text-black-400 text-xs font-semibold">
                 ENGAGEMENT</span> <span style={{backgroundColor: company?.engagement_score_color ?? 'white'}}
                                         className="text-xs px-2 py-1 rounded-md text-uppercase font-bold bg-green-500 text-white ">
                                        {company?.engagement_score}
            </span>
                                        </div>
                                        <div className="display-block text-center">
                                            <div className="batteryContainer mt-3">
                                                <div className="batteryOuter">
                                                    <div id="batteryLevel" style={{
                                                        backgroundColor: company?.engagement_score_color ?? 'white',
                                                        width: company?.engagement_score_percentage ?? 10
                                                    }} className="bg-green-500 w-5/6"></div>
                                                </div>
                                                <div
                                                    style={{backgroundColor: company?.engagement_score_color ?? 'white'}}
                                                    className="batteryBump"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-2">
                            <div className="card bg-gradient shadow-md p-0">
                                <div className="card-body p-0 pt-3">
                                    <div className="row">
                                        <div className="display-block text-center mb-3">
                <span className="text-gray-600 text-sm font-semibold">
                  CONTACTS </span>
                                        </div>
                                    </div>
                                    <div className="text-sm border-gray-200 table-responsive">
                                        <div className="text-sm border-gray-200 ">
                                            <table className='col-12'>
                                                {company?.contacts?.length > 0 ?
                                                    <>
                                                        <thead>
                                                        <tr style={{borderBottom: '1px solid #3333331f'}}>
                                                            <th className="px-6 py-1 text-xs text-gray-500">
                                                                NAME
                                                            </th>
                                                            <th className="px-6 py-1 text-xs text-gray-500">
                                                                EMAIL
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <PaginatedContact onContactSelected={onContactSelected}
                                                                          items={company?.contacts}/>
                                                    </> :
                                                    <tbody className="bg-white">
                                                    <tr className="whitespace-nowrap text-center">
                                                        <td colSpan="2" className="p-3 pt-0 text-muted">
                                                            <span className="material-icons">info_outline</span>
                                                            <span className="d-block">No contacts records found</span>
                                                        </td>
                                                    </tr>
                                                    </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-5">
                            <div className="card bg-gradient shadow-md p-0">
                                <div className="card-body p-0 pt-3">
                                    <div className="row">
                                        <div className="display-block text-center mb-3">
                <span className="text-gray-600 text-sm font-semibold">
                  ACTIVITY SUMMARY </span>
                                        </div>
                                    </div>
                                    <div className="text-sm border-gray-200 table-responsive">
                                        <div className="text-sm border-gray-200 ">
                                            <table className='col-12'>
                                                {company?.activities != null ?
                                                    <>
                                                        <thead>
                                                        <tr style={{borderBottom: '1px solid #3333331f'}}>
                                                            <th className="px-6 py-1 text-xs text-gray-500">
                                                                DATE
                                                            </th>
                                                            <th className="px-6 py-1 text-xs text-gray-500">
                                                                ACTIVITY
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <PaginatedUsers items={company?.activities}
                                                                        options={props?.options} from_company={true}/>
                                                    </> :
                                                    <tbody className="bg-white">
                                                    <tr className="whitespace-nowrap text-center">
                                                        <td colSpan="2" className="p-3 pt-0 text-muted">
                                                            <span className="material-icons">info_outline</span>
                                                            <span className="d-block">No activities records found</span>
                                                        </td>
                                                    </tr>
                                                    </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CompanyUpdate company={props?.company} onUpdate={props?.onUpdate}/>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop/>
            </Sheet>
        </div>
    );
}

export default CompanyProfile;
